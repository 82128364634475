import React from 'react'
import { Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import moment from 'moment';
import { isImageURL } from '../helpers';

export function backDesignPdf(data, finalStyle) {
  console.log('finalStyle 2', finalStyle)
  return (
    <Page size={[499, 794]} orientation="portrait" style={{ flex: 1, backgroundColor: '#2c2822' }}>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: '#2c2822',
        }}
      >
        <Image
          src={{ uri: "https://firebasestorage.googleapis.com/v0/b/brizsa-app.appspot.com/o/fondo-carnet-digital.png?alt=media&token=2b5f9b9f-a942-4e42-8eba-6af21afbfbcb" }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            resizeMode: 'cover',
            opacity: 0.9,
          }}
        />
        <View style={{ paddingVertical: 30, paddingHorizontal: 20 }}>
          <View style={{ alignItems: 'center', marginBottom: 50, marginTop: 60 }}>
            <Image
              src={{ uri: "https://firebasestorage.googleapis.com/v0/b/brizsa-app.appspot.com/o/brisza-logotipo-nobg.png?alt=media&token=33c4d74c-38aa-437b-b62a-d2e5297b94ff" }}
              style={{
                height: 100,
                width: 220,
              }}
            />
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 80 }}>
            <View style={{ marginRight: 10, marginTop: 5 }}>
              <Image
                src={{ uri: isImageURL(data.imageUrl) ? data.imageUrl : "https://firebasestorage.googleapis.com/v0/b/brizsa-app.appspot.com/o/anonymous.jpeg?alt=media&token=f13d6e1c-5578-4961-b730-96d262870540" }}
                style={finalStyle}
              />
            </View>
            <View style={{ marginLeft: 10 }}>
              <Text style={{
                color: 'white',
                fontSize: 18,
                overflow: 'hidden', // Hide overflow
                whiteSpace: 'nowrap', // Ensure text is on a single line
                textOverflow: 'ellipsis', // Add ellipsis for overflow
                fontFamily: 'LeGrand',
                marginBottom: 5,
              }}>
                {data.firstname}
              </Text>
              <Text style={{
                color: 'white',
                fontSize: 18,
                overflow: 'hidden', // Hide overflow
                whiteSpace: 'nowrap', // Ensure text is on a single line
                textOverflow: 'ellipsis', // Add ellipsis for overflow
                fontFamily: 'LeGrand',
                marginBottom: 5,
              }}>
                {data.lastname}
              </Text>
              {/* <Text style={{ color: 'white', fontSize: 40 }}>{`${moment(moment(data.birthdate, 'DD/MM/YYYY')).format('DD MM YYYY')}`}</Text> */}
              <Text style={{
                color: 'white', fontSize: 18, fontFamily: 'LeGrand', marginBottom: 10
              }}>{data.id}</Text>
              <Image
                src={{ uri: data.qrUrl }}
                style={{
                  height: 100,
                  width: 100,
                }}
              />
            </View>
          </View>
        </View>
        <View style={{ flex: 1, position: 'absolute', bottom: 140, alignSelf: 'center', paddingHorizontal: 40 }}>
          <Text style={{ flex: 1, color: 'white', marginBottom: 5, fontSize: 18, alignSelf: 'center' }}>
            Este carnet es personal e intransferible. De darse mal uso este podrá ser retenido por el personal encargado de BRISZA Asia.
            De ser necesario se exigirá la identificación del titular.
          </Text>
        </View>
        <View style={{ flex: 1, position: 'absolute', bottom: 20, alignSelf: 'center' }}>
          <Text style={{ color: 'white', marginBottom: 5, fontSize: 30, alignSelf: 'flex-end', fontFamily: 'LeGrand' }}>Verano 24'</Text>
        </View>
      </View>
    </Page>
  )
}