import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import isotype from './assets/isotype-svg.svg';
import { Spin } from 'antd';
import background from './assets/fondo_carnet.jpg';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, get } from "firebase/database";
import { isImageURL } from './helpers';
import LottieAnimation from './Lottie';
import successLotie from './assets/success.json';
import QrCodeScanner from './QrCodeScanner';

export function Verified() {

  const navigate = useNavigate();
  const [number, setNumber] = useState('');
  const [user, setUser] = useState(null);
  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getDatabase();

  const fileInputRef = useRef();

  // Function to trigger the hidden file input
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('user', user)
      setUser(user);
      setLoading(false);
    })
    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split('/');
    const qrNumber = parts[parts.indexOf('qr') + 1];
    setNumber(qrNumber);
  }, []);


  useEffect(() => {
    const fetchPartner = async () => {
      if (!user || !number) return;

      try {
        setLoading(true);
        console.log('fetchPartner');

        const partnersRef = ref(db, 'partners');
        const snapshot = await get(partnersRef);

        if (snapshot.exists()) {
          const partners = snapshot.val();
          const foundPartner = Object.values(partners).find(partner => partner.id === number);

          if (foundPartner) {
            console.log('foundPartner', foundPartner);
            setPartner(foundPartner);
          } else {
            console.log('No partner found with the given id');
            setPartner(null);
            setError('error');
          }
        } else {
          console.log('No partners data available');
          setPartner(null);
          setError('error');
        }
      } catch (err) {
        console.error('Error fetching partners:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPartner();
  }, [user, number]);


  if (loading) {
    return (
      <div style={{
        display: 'flex', // Use flexbox for centering
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'center', // Center vertically
        alignItems: 'center', // Center horizontally
        width: '100%', // Full width
        height: '100vh', // Full viewport height
        position: 'relative', // Relative because of the absolute child
        backgroundColor: '#b48f6d',
      }}>
        <Spin size='large' />
      </div>
    )
  }

  if (user) {
    return (
      <div style={{
        display: 'flex', // Use flexbox for centering
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'center', // Center vertically
        alignItems: 'center', // Center horizontally
        width: '100%', // Full width
        minHeight: '100vh', // Full viewport height
        background: 'linear-gradient(to bottom, #b48f6d, #c7a589)', // Example gradient from brown to lighter brown
      }}>
        {/* Text content */}
        {loading ? <Spin /> :
          partner ? (
            <div style={{ textAlign: "center" }}>
              <img
                src={isImageURL(partner.imageUrl) ? partner.imageUrl : "https://firebasestorage.googleapis.com/v0/b/brizsa-app.appspot.com/o/anonymous.jpeg?alt=media&token=f13d6e1c-5578-4961-b730-96d262870540"}
                style={{ width: 200 }}
              />
              <div style={{ textAlign: 'initial' }}>
                <div style={{ fontSize: 24, marginBottom: 10, marginTop: 20 }}>
                  <span style={{ fontWeight: 'bold' }}>Nombre: </span><span>{partner.firstname} {partner.lastname}</span>
                </div>
                <div style={{ fontSize: 24, marginBottom: 10 }}>
                  <span style={{ fontWeight: 'bold' }}>DNI: </span><span>{partner.id}</span>
                </div>
                <div style={{ fontSize: 24, marginBottom: 10 }}>
                  <span style={{ fontWeight: 'bold' }}>F. de nacimiento: </span><span>{partner.birthdate}</span>
                </div>
                <div style={{ width: 100, height: 100, margin: 'auto' }}>
                  <LottieAnimation animationData={successLotie} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, justifyContent: 'space-around', marginBottom: 150, alignItems: 'flex-start' }}>
                  <QrCodeScanner />
                  <div
                    onClick={() => navigate('/dni')}
                    style={{
                      backgroundColor: '#4CAF50', // Green background
                      color: 'white', // White text
                      padding: '10px 15px', // Padding
                      border: 'none', // No border
                      borderRadius: '4px', // Rounded corners
                      cursor: 'pointer', // Pointer cursor on hover
                      fontSize: '16px', // Font size
                    }}>
                    Ingresar DNI
                  </div>
                </div>
              </div>

            </div>
          ) : error ? (
            <div>Error, no hay socio con ese número</div>
          ) : (
            <div>Error en la búsqueda</div>
          )
        }
      </div>
    )
  } else {

    return (
      <div style={{
        display: 'flex', // Use flexbox for centering
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'center', // Center vertically
        alignItems: 'center', // Center horizontally
        width: '100%', // Full width
        height: '100vh', // Full viewport height
        position: 'relative', // Relative because of the absolute child
        backgroundColor: '#b48f6d',
      }}>
        {/* Overlay with background image */}
        <div style={{
          backgroundColor: '#b48f6d',
          backgroundImage: `url(${background})`, // Put background image
          backgroundSize: 'cover', // Cover whole area
          position: 'absolute', // Absolute to overlay the parent
          width: '100%',
          height: '100%',
          opacity: 0.5, // Apply opacity to the image only
          zIndex: 1, // Under the text
        }} />
        {/* Text content */}
        <img
          src={isotype}
          className='main-app-isotype'
        />
        <div style={{
          backgroundColor: '#2c2822',
          fontWeight: 'bold',
          color: 'white',
          fontSize: '4vw', // Responsive font size based on viewport width
          textAlign: 'center', // Center text
          zIndex: 2, // Above the background
          position: 'absolute', // Ensure the stacking context is above the background
          top: '70%',
          cursor: 'pointer',
          padding: '10px 20px',
          paddingBottom: 10,
          borderRadius: 5,
        }}
          onClick={() => navigate('/socios')}
        >
          {loading ? <Spin /> : (<div>{number}</div>)}
        </div>
      </div>
    );
  }
}
