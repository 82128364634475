import './App.css';
import { useState, useEffect } from 'react';
import { getDatabase, ref, child, get, push, set, onValue } from "firebase/database";
import backgroundImage from './assets/brisza.png';
import { apiClient } from './api';
import moment from 'moment';
import { getStorage, ref as firebaseRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { dataURLToBlob } from './helpers';
import { message, Spin, QRCode } from 'antd';
import { downloadPDF } from './GetReactPDF';
import NewCarnetPDF from './NewCarnetPDF';
import RequestCard from './RequestCard';
import { Radio } from 'antd';
import PartnersCard from './PartnerCard';
import { Pagination } from 'antd';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export function AdminPage() {

  const db = getDatabase();
  const [forms, setForms] = useState([]);
  const [formsRaw, setFormsRaw] = useState([]);
  const [partnersRaw, setPartnersRaw] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [promotorSearchTerm, setPromotorSearchTerm] = useState('');
  const [dataType, setDataType] = useState('pending');
  const pageSize = 50;
  const [currentPage, setCurrentPage] = useState(1);

  console.log('forms', forms)

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = searchTerm.length > 2 ? forms : forms.slice(indexOfFirstItem, indexOfLastItem);

  console.log('currentItems', currentItems, indexOfFirstItem, indexOfLastItem)

  const uploadImageToStorage = async (canvasId, storagePath) => {
    console.log('canvasId', canvasId)
    const canvas = document.getElementById(canvasId)?.querySelector('canvas');
    if (!canvas) {
      throw new Error('Canvas element not found');
    }

    const dataUrl = canvas.toDataURL('image/png');
    const blob = dataURLToBlob(dataUrl);

    // Get a reference to the storage service
    const storage = getStorage();
    // Create a storage reference from our storage service
    const ref = firebaseRef(storage, storagePath);

    try {
      const snapshot = await uploadBytes(ref, blob);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      throw new Error(`Error uploading image: ${error}`);
    }
  };

  const handleSearchChange = (event, type) => {
    console.log('event', event.target.value, type)
    if (type === 'promotor') {
      setPromotorSearchTerm(event.target.value);
    } else {
      setSearchTerm(event.target.value);
    }
    filterForms(event.target.value, type);
  };

  const filterForms = (searchString, type) => {
    const filtered = formsRaw.filter(form => {
      const searchQuery = type === 'person' ? `${form.firstname} ${form.lastname}`.toLowerCase() : form.invitedBy.toLowerCase();
      return searchQuery.includes(searchString.toLowerCase()) && (dataType === 'partners' ? form.accepted : (!form.accepted && !form.declined));
    });

    setForms(filtered);
  };



  const sendEmail = async (form, pdfData) => {
    console.log('sendmail')
    try {
      const response = await apiClient.post(process.env.REACT_APP_API_URL_PDF, {
        recipientEmail: form.email,
        recipientName: form.firstname,
        pdfData: pdfData,
      });
      console.log('Email sent:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error sending email:', error);
      throw error;
    }
  };

  const handlePDF = async (form, mail) => {

    const styles = {
      imgRot1: {
        width: 150,
        height: 150,
      },
    }

    const pdf = await downloadPDF({
      document: (
        <NewCarnetPDF
          documentTitle={`Carnet Brisza ${form.id}`}
          data={form}
          finalStyle={styles.imgRot1}
        />
      ),
      fileName: `Carnet de socio 1${form.id}.pdf`,
      mail: mail
    })

    return pdf;
  };




  useEffect(() => {
    const formsRef = ref(db, 'forms');
    onValue(formsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formData = Object.entries(snapshot.val()).map(([key, value]) => ({
          key, // Include the key in the object
          ...value, // Include the rest of the object properties
        }));
        let filteredData = formData.filter((form) => dataType === 'partners' ? form.accepted : (!form.accepted && !form.declined))
        console.log('formData', filteredData)
        setForms(filteredData);
        setFormsRaw(formData);
      }
    });

    const partnersRef = ref(db, 'partners');
    onValue(partnersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const partnersData = Object.entries(snapshot.val()).map(([key, value]) => ({
          key, // Include the key in the object
          ...value, // Include the rest of the object properties
        }));
        setPartnersRaw(partnersData);
      }
    });
  }, [dataType])


  const handleAcceptMember = async (key, form) => {
    setLoading(true)
    console.log('accept formdata', key, form)
    const qrUrl = await uploadImageToStorage(`myqrcode${form.id}`, `qrs/${form.id}`)
    console.log('qrUrl', qrUrl);
    form.qrUrl = qrUrl;
    const pdf = await handlePDF(form, true)
    console.log('pdf', pdf);
    form.accepted = true;
    setLoading(false)

    set(ref(db, `forms/${key}`), form)
      .then(() => {
        sendEmail(form, pdf);
        createMember(form);
        setLoading(false)
        console.log('Data submitted!');
        message.destroy()
        message.success('Socio aceptado')
      })
      .catch((error) => {
        console.error('Error submitting data: ', error);
        setLoading(false)
      });
  }


  const handleEditMember = async (key, form) => {
    setLoading(true)
    console.log('accept formdata', key, form)
    const qrUrl = await uploadImageToStorage(`myqrcode${form.id}`, `qrs/${form.id}`)
    console.log('qrUrl', qrUrl);
    form.qrUrl = qrUrl;
    const pdf = await handlePDF(form, true)
    console.log('pdf', pdf);
    form.accepted = true;
    form.newPDF = true;
    setLoading(false)

    set(ref(db, `forms/${key}`), form)
      .then(() => {
        message.destroy()
        message.success('Socio editado')
      })
      .catch((error) => {
        console.error('Error submitting data: ', error);
        setLoading(false)
      });
  }

  const handleRejectMember = (key, form) => {
    console.log(' reject formdata', key, form)
    form.declined = true;
    set(ref(db, `forms/${key}`), form)
      .then(() => {
        console.log('Data submitted!');
        message.destroy()
        message.success('Socio denegado!')

      })
      .catch((error) => {
        console.error('Error submitting data: ', error);
      });
  }

  const createMember = (formData) => {
    console.log('member', formData)
    const membersRef = ref(db, 'partners/');
    delete formData.key
    delete formData.accepted
    push(membersRef, formData)
      .then((newMemberRef) => {
        console.log('Data submitted successfully to members:', newMemberRef.key);
        // Here you can navigate to a thank you page or reset the form, etc.
      })
      .catch((error) => {
        console.error('Error submitting data to members: ', error.message);
      });
  }

  const handleDownload = async () => {

    const filterFields = (data) => {
      return data.map(item => ({
        firstname: item.firstname || '',
        lastname: item.lastname || '',
        beach: item.beach || '',
        birthdate_day: item.birthdate_day || '',
        birthdate_month: item.birthdate_month || '',
        birthdate_year: item.birthdate_year || '',
        id: item.id || '',
        phone: item.phone || '',
        invitedBy: item.invitedBy || '',
        email: item.email || '',
      }));
    };

    // Filter the data for both forms and partners
    const filteredForms = filterFields(formsRaw);
    const filteredPartners = filterFields(partnersRaw);

    // Create a new workbook and sheets
    const wb = XLSX.utils.book_new();
    const formsSheet = XLSX.utils.json_to_sheet(filteredForms);
    const partnersSheet = XLSX.utils.json_to_sheet(filteredPartners);

    const currentYear = new Date().getFullYear();

    // Function to aggregate data by a specific field
    const aggregateDataByField = (data, field) => {
      return data.reduce((acc, item) => {
        const key = item[field] || 'Unknown'; // Handle cases where field might be missing
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});
    };

    // Function to calculate age and group into age categories
    const aggregateAgeGroups = (data) => {
      const ageGroups = {
        '24-26': 0,
        '27-29': 0,
        '30-32': 0,
        '33-35': 0,
        '36-38': 0,
        '39-40': 0,
        '40+': 0
      };

      data.forEach(item => {
        const birthYear = parseInt(item.birthdate_year);
        if (!birthYear) return;

        const age = currentYear - birthYear;
        if (age >= 24 && age <= 26) ageGroups['24-26']++;
        else if (age >= 27 && age <= 29) ageGroups['27-29']++;
        else if (age >= 30 && age <= 32) ageGroups['30-32']++;
        else if (age >= 33 && age <= 35) ageGroups['33-35']++;
        else if (age >= 36 && age <= 38) ageGroups['36-38']++;
        else if (age >= 39 && age <= 40) ageGroups['39-40']++;
        else if (age > 40) ageGroups['40+']++;
      });

      return Object.entries(ageGroups).map(([key, value]) => ({ AgeGroup: key, Count: value }));
    };

    // Example usage in your handleDownload function
    const invitedByCounts = aggregateDataByField(partnersRaw, 'invitedBy');
    const beachCounts = aggregateDataByField(partnersRaw, 'beach');
    const ageGroups = aggregateAgeGroups(partnersRaw);

    // Convert the counts to a format suitable for the spreadsheet
    const invitedByData = Object.entries(invitedByCounts).map(([key, value]) => ({ InvitedBy: key, Count: value }));
    const beachData = Object.entries(beachCounts).map(([key, value]) => ({ Beach: key, Count: value }));

    // Add to analytics sheet
    const analyticsSheet = XLSX.utils.json_to_sheet([
      { 'Category': 'Invited By', 'Count': null },
      ...invitedByData,
      { 'Category': null, 'Count': null }, // Blank row
      { 'Category': 'Beach', 'Count': null },
      ...beachData,
      { 'Category': null, 'Count': null }, // Blank row
      { 'Category': 'Age Groups', 'Count': null },
      ...ageGroups
    ]);

    const columnWidths = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 30 }];

    // Apply the column widths to each sheet
    formsSheet['!cols'] = columnWidths;
    partnersSheet['!cols'] = columnWidths;
    analyticsSheet['!cols'] = [{ wch: 10 }, { wch: 10 }, { wch: 30 }, { wch: 30 },]; // Adjust widths for the analytics sheet as needed

    // Append sheets to the workbook
    XLSX.utils.book_append_sheet(wb, formsSheet, 'Forms');
    XLSX.utils.book_append_sheet(wb, partnersSheet, 'Partners');
    XLSX.utils.book_append_sheet(wb, analyticsSheet, 'Analytics (Partners)');

    // Write workbook and download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), 'brisza-data.xlsx');
  };

  // String to ArrayBuffer function for XLSX
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };


  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center', // Center horizontally
      height: '100vh', // Full viewport height
      backgroundImage: `url(${backgroundImage})`, // Replace with your image path
      backgroundSize: 'cover', // Cover the entire viewport
      backgroundPosition: 'center', // Center the background image
      // filter: 'blur(4px)'
    }}>
      <div>
        <div className="admin-header" style={{ margin: 20 }}>Admin Panel</div>
        {/* <div className="instruction-text">
          Acepta o elimina las solicitudes de los carnets
        </div> */}
        <div style={{ textAlign: 'center' }}>
          <div onClick={handleDownload} style={{ padding: 10, cursor: 'pointer', display: 'inline-block', borderRadius: 10, color: 'white', backgroundColor: 'green', marginBottom: 20 }}>
            Descargar datos
          </div>
          <div>
            <div style={{ color: 'white', marginBottom: 10 }}>
              Total de solicitudes: {formsRaw.length}
            </div>
            <Radio.Group defaultValue="pending" onChange={(e) => setDataType(e.target.value)} buttonStyle="solid">
              <Radio.Button value="partners">Socios</Radio.Button>
              <Radio.Button value="pending">Pendientes</Radio.Button>
            </Radio.Group>
            {dataType === 'partners' ? (
              <div style={{ color: 'white', marginBottom: 10, marginTop: 20 }}>
                Total de socios: {partnersRaw.length}
              </div>) : (
              <div style={{ color: 'white', fontWeight: 'bold', marginBottom: 10, marginTop: 20 }}>
                Solicitudes pendientes: {forms.length}
              </div>
            )}
          </div>

        </div>
        <div style={{ textAlign: 'center' }}>
          <input
            type="text"
            placeholder=" Buscar invitado por nombre o apellido"
            value={searchTerm}
            style={{ width: 300, minHeight: 30, margin: 10, borderRadius: 10 }}
            onChange={(e) => handleSearchChange(e, 'person')}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <input
            type="text"
            placeholder=" Buscar promotor por nombre o apellido"
            value={promotorSearchTerm}
            style={{ width: 300, minHeight: 30, margin: 10, borderRadius: 10 }}
            onChange={(e) => handleSearchChange(e, 'promotor')}
          />
        </div>
        <div>
          <div style={{ backgroundColor: 'white' }}>
            <Pagination
              current={currentPage}
              onChange={(page) => setCurrentPage(page)}
              total={forms.length}
              pageSize={pageSize}
            />
          </div>
          {
            dataType === 'partners' ? (
              currentItems.map((form, index) => (
                <PartnersCard
                  key={index}
                  index={index}
                  form={form}
                  loading={loading}
                  handlePDF={() => handlePDF(form, false)}
                  handleEditMember={() => handleEditMember(form.key, form)}
                />
              ))
            ) : (
              currentItems.map((form, index) => (
                <RequestCard
                  key={index}
                  index={index}
                  form={form}
                  loading={loading}
                  handleAcceptMember={handleAcceptMember}
                  handleRejectMember={handleRejectMember}
                />
              ))
            )
          }
        </div>
      </div>

    </div>
  );
}

