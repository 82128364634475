import React, { Fragment } from 'react'
import { Document, StyleSheet, Font } from "@react-pdf/renderer";
import { frontDesignPdf } from './newPdfDesigns/newFrontDesign';
import { backDesignPdf } from './newPdfDesigns/newBackDesign';
import font from './assets/fonts/GigaSans-Regular.otf'
import font2 from './assets/fonts/LeGrandCapital-Regular.ttf'

Font.register({
  family: 'Giga Sans',
  fonts: [
    { src: font },
  ]
});

Font.register({
  family: 'LeGrand',
  fonts: [
    { src: font2 },
  ]
});


const styles = StyleSheet.create({

  documentStyle: {
    fontFamily: 'Giga Sans',
    backgroundColor: "#ffffff",
  },
});


const CarnetPDF = ({ documentTitle, data, finalStyle }) => {

  return (
    <Document title={documentTitle} style={styles.documentStyle}>
      {frontDesignPdf(data)}
      {backDesignPdf(data, finalStyle)}
    </Document>
  );
}

export default CarnetPDF;