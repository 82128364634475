import {
  pdf
} from '@react-pdf/renderer';

export const getPDF_base64 = (props) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!props.document) {
        reject();
        return;
      }
      // Creamos el PDF con el documento de react-pdf
      const instance = pdf(props.document);
      let blob = await instance.toBlob();

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        resolve({
          base64: reader.result.split(',')[1],
          filename: props.fileName,
          type: blob.type,
          size: blob.size
        });
      }

    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

export const downloadPDF = async (props) => {
  if (!props.document) {
    throw new Error("No document provided");
  }

  if (props.mail === true) {
    try {
      const instance = pdf(props.document);
      const blob = await instance.toBlob();

      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          resolve({
            base64: reader.result.split(',')[1],
            filename: props.fileName,
            type: blob.type,
            size: blob.size
          });
        };
        reader.onerror = reject;
      });
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error for the caller to handle
    }
  } else {
    try {
      const instance = pdf(props.document);
      const blob = await instance.toBlob();

      // Browser-specific logic for downloading the file
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = data;
        link.target = "_blank";
        link.download = props.fileName;
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(data), 100);
      }
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error for the caller to handle
    }
  }
};
