import React from 'react';
import './App.css';
import logo from './assets/brisza-logotipo.png';
import isotype from './assets/isotype-svg.svg';
import background from './assets/fondo_carnet.jpg';
import { useNavigate } from 'react-router-dom';

export function Main() {
  const navigate = useNavigate();
  return (
    <div style={{
      display: 'flex', // Use flexbox for centering
      flexDirection: 'column', // Stack children vertically
      justifyContent: 'center', // Center vertically
      alignItems: 'center', // Center horizontally
      width: '100%', // Full width
      height: '100vh', // Full viewport height
      position: 'relative', // Relative because of the absolute child
      backgroundColor: '#b48f6d',
    }}>
      {/* Overlay with background image */}
      <div style={{
        backgroundColor: '#b48f6d',
        backgroundImage: `url(${background})`, // Put background image
        backgroundSize: 'cover', // Cover whole area
        position: 'absolute', // Absolute to overlay the parent
        width: '100%',
        height: '100%',
        opacity: 0.5, // Apply opacity to the image only
        zIndex: 1, // Under the text
      }} />
      {/* Text content */}
      <img
        src={isotype}
        className='main-app-isotype'
      />
      <div style={{
        backgroundColor: '#2c2822',
        fontWeight: 'bold',
        color: 'white',
        fontSize: '4vw', // Responsive font size based on viewport width
        textAlign: 'center', // Center text
        zIndex: 2, // Above the background
        position: 'absolute', // Ensure the stacking context is above the background
        top: '70%',
        cursor: 'pointer',
        padding: '10px 20px',
        paddingBottom: 10,
        borderRadius: 5,
      }}
        onClick={() => navigate('/socios')}
      >
        Socios
      </div>
    </div>
  );
}
