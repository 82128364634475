import React, { useState } from 'react';
import firebase from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";


const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSignup = async (event) => {
    event.preventDefault();
    const auth = getAuth();

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Now we try to sign up the user
        return createUserWithEmailAndPassword(auth, email, password);
      })
      .then((userCredential) => {
        // Signed up 
        const user = userCredential.user;
        console.log('success user', user)
        // User is now signed up and will remain logged in
      })
      .catch((error) => {
        console.log('error', error)
        const errorCode = error.code;
        const errorMessage = error.message;
        // Handle Errors here.
      });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <form onSubmit={handleSignup} style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          style={{ padding: '10px', margin: '10px 0', borderRadius: '5px', border: '1px solid gray' }}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={{ padding: '10px', margin: '10px 0', borderRadius: '5px', border: '1px solid gray' }}
        />
        <button type="submit" style={{
          padding: '10px', margin: '10px 0', borderRadius: '5px', border: 'none', backgroundColor: '#b48f6d', color: 'white', cursor: 'pointer'
        }}>
          Sign Up
        </button>
      </form>
      {error ? <p style={{ color: 'red' }}>{error}</p> : null}
    </div>
  );
};

export default SignupForm;