import React, { useState } from 'react';
import logo from './assets/brisza-logotipo.png';
import LottieAnimation from './Lottie';
import { getDatabase, ref, push } from "firebase/database";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { ref as fileRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import successLotie from './assets/success.json';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getStorage } from 'firebase/storage';
import { DatePicker, Select, message, Upload } from 'antd';
import { apiClient } from './api';

dayjs.extend(customParseFormat);

const dateFormat = 'DD/MM/YYYY';


const dayOptions = Array.from({ length: 31 }, (_, i) => ({
  value: `${i + 1}`,
  label: `${i + 1}`
}));

// Generating options for months
const monthOptions = Array.from({ length: 12 }, (_, i) => ({
  value: `${i + 1}`,
  label: `${i + 1}`
}));

// Generating options for years
const yearOptions = Array.from({ length: 2023 - 1930 + 1 }, (_, i) => ({
  value: `${2023 - i}`,
  label: `${2023 - i}`
})).reverse();



// import { storage } from './firebase';
// import axios from 'axios';

const Partners = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    id: '',
    birthdate: '',
    invitedBy: '',
    imageUrl: '',
    email: '',
    beach: '',
    phone: '',
    instagram: '',
    birthdate_day: '',
    birthdate_month: '',
    birthdate_year: '',

  });
  const [flipForm, setFlipForm] = useState(false); // New state for flip effect
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const db = getDatabase();
  const storage = getStorage();

  const handleChangeImage = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      processAndUploadImage(info.file.originFileObj);
    }
  };


  const processAndUploadImage = (event) => {

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = 200;
        canvas.height = 200;

        const scale = Math.min(img.width, img.height) / 200;
        const x = (img.width - 200 * scale) / 2;
        const y = (img.height - 200 * scale) / 2;

        ctx.drawImage(img, x, y, 200 * scale, 200 * scale, 0, 0, 200, 200);

        canvas.toBlob((blob) => {
          // Firebase storage reference
          // Upload the file to Firebase Storage
          const storageRef = fileRef(storage, `images/${formData.id || formData.lastname}/`);
          uploadBytes(storageRef, blob).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
              setLoading(false);
              setFormData({ ...formData, imageUrl: downloadURL });
            }).catch((error) => {
              setLoading(false);
            });
          }).catch((error) => {
            setLoading(false);
          });
        }, 'image/jpeg');
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(event);
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Solo puedes subir foto en formato JPG o PNG');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('La foto no puede ser mayor a 2MB');
    }

    if (!formData.id) {
      message.error('Ingresa tu DNI primero');
    }
    return isJpgOrPng && isLt2M && formData.id;
  };

  const handleInputChange = (e) => {
    console.log('e', e)
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const sendEmail = async () => {
  //   console.log('sendmail')
  //   try {
  //     const response = await apiClient.post(process.env.REACT_APP_API_URL, {
  //       recipientEmail: formData.email,
  //       recipientName: formData.firstname,
  //     });
  //     console.log('Email sent:', response.data);
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error sending email:', error);
  //     throw error;
  //   }
  // };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
    return regex.test(email);
  };

  const validateID = (id) => {
    return /^\d{7,}$/.test(id); // Regex for at least 7 digits
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // Check each field individually and set specific error messages
    if (!formData.firstname) {
      setError('Por favor completa el campo de nombre');
      message.error('Por favor completa el campo de nombre')
      return;
    }
    if (!formData.lastname) {
      setError('Por favor completa el campo de apellido');
      message.error('Por favor completa el campo de apellido')
      return;
    }
    if (!formData.id) {
      setError('Por favor ingresa tu Documento de Identidad');
      message.error('Por favor ingresa tu Documento de Identidad')
      return;
    }
    if (!validateID(formData.id)) {
      setError('El Documento de identidad debe contener al menos 7 dígitos y solo números');
      message.error('El Documento de identidad debe contener al menos 7 dígitos y solo números')
      return;
    }
    if (!formData.email) {
      setError('Por favor ingresa tu correo electrónico');
      message.error('Por favor ingresa tu correo electrónico')
      return;
    }
    if (!validateEmail(formData.email)) {
      setError('Por favor ingresa un correo electrónico válido');
      message.error('Por favor ingresa un correo electrónico válido')
      return;
    }
    if (!formData.phone) {
      setError('Por favor completa el campo de teléfono/celular');
      message.error('Por favor completa el campo de teléfono/celular')
      return;
    }
    if (!formData.birthdate_day || !formData.birthdate_month || !formData.birthdate_year) {
      setError('Por favor completa tu fecha de nacimiento');
      message.error('Por favor completa tu fecha de nacimiento')
      return;
    }
    if (!formData.invitedBy) {
      setError('Por favor indica quién te ha invitado');
      message.error('Por favor indica quién te ha invitado')
      return;
    }
    if (!formData.imageUrl) {
      setError('Por favor sube una imagen para tu carnet o espera a que cargue');
      message.error('Por favor sube una imagen para tu carnet o espera a que cargue')
      return;
    }
    if (!formData.instagram) {
      setError('Por favor completa el campo de Instagram');
      message.error('Por favor completa el campo de Instagram')
      return;
    }

    setIsSubmitting(true);

    formData.createdAt = dayjs().format('DD/MM/YYYY');
    formData.birthdate = `${formData.birthdate_day}/${formData.birthdate_month}/${formData.birthdate_year}`;

    if (!formData.beach) {
      formData.beach = 'No especificado'
    }
    // Push the data to the 'forms' path
    push(ref(db, 'forms/'), formData)
      .then(() => {
        // Data pushed successfully
        setIsSubmitting(false);
        setFlipForm(true); // Trigger the flip effect
        // sendEmail();
        // Here you can navigate to a thank you page or reset the form, etc.
        console.log('Data submitted!');
      })
      .catch((error) => {
        // Handle the error
        setIsSubmitting(false);
        setError(error.message);
        console.error('Error submitting data: ', error);
      });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Subir foto
      </div>
    </div>
  )

  console.log('formData', formData)

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column', // Stack children vertically
      alignItems: 'center', // Center vertically
      justifyContent: 'center', // Center horizontally
      backgroundColor: '#2c2822', // Replace with your image path
      minHeight: '100vh', // Set the min height to screen height
      // filter: 'blur(4px)'
    }}>

      <img
        src={logo}
        alt={'brisza-logo'}
        style={{
          width: '40%',
          marginBottom: 20,
          marginTop: 20
        }}
      />
      <form onSubmit={handleSubmit} className={`form-container ${flipForm ? 'flip-horizontal' : ''}`}>
        {flipForm ? (       // If flipForm is true, show the success message  
          <div style={{ transform: 'rotateY(180deg)', textAlign: 'center' }}>
            <div style={{ fontSize: 24, marginBottom: 20 }}>¡Listo!</div>
            <div style={{ fontSize: 18 }}>En caso seas aceptado como socio, recibirás un correo electrónico con tu carnet digital.</div>
            <LottieAnimation animationData={successLotie} />
          </div>
        ) : (
          <>
            <div className="form-title" style={{ textAlign: 'center', width: '100%', alignItems: 'center', fontSize: 20, marginBottom: 20 }}>
              Ingresa tus datos para solicitar un carnet
            </div>
            {error && <div style={{ backgroundColor: '#ff000030', padding: 15, borderRadius: 10, marginBottom: 10 }}>{error}</div>}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <input type="text" id="firstname" name="firstname" placeholder="Nombre (ej. Camila)" className="input-text" onChange={handleInputChange} />
              <span style={{ color: 'red', marginLeft: 5 }}> *</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <input type="text" id="lastname" name="lastname" placeholder="Apellido (ej. Fernandez)" className="input-text" onChange={handleInputChange} />
              <span style={{ color: 'red', marginLeft: 5 }}> *</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <input type="text" id="id" name="id" placeholder="Documento de identidad (ej. 65184429)" className="input-text" onChange={handleInputChange} />
              <span style={{ color: 'red', marginLeft: 5 }}> *</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <input type="text" id="email" name="email" placeholder="Correo electrónico (ej. camifernandez@gmail.com)" className="input-text" onChange={handleInputChange} />
              <span style={{ color: 'red', marginLeft: 5 }}> *</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <input type="text" id="phone" name="phone" className="input-text" placeholder="Celular (ej. +51949234232)" onChange={handleInputChange} />
              <span style={{ color: 'red', marginLeft: 5 }}> *</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <input type="text" id="instagram" name="instagram" placeholder="Instagram (ej. @camifernandez)" className="input-text" onChange={handleInputChange} />
              <span style={{ color: 'red', marginLeft: 5 }}> *</span>
            </div>
            <input type="text" id="beach" name="beach" placeholder="Playa (ej. Playa bonita)" className="input-text" onChange={handleInputChange} />

            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <div htmlFor="birthdate" className="input-label">Fecha de nacimiento</div>
              <span style={{ color: 'red', marginLeft: 5 }}> *</span>
            </div>

            {/* <input type="date" id="birthdate" name="birthdate" className="input-date" onChange={handleInputChange} /> */}
            <div style={{ width: '100%' }}>
              {/* <DatePicker placeholder="DD/MM/YYYY" format={dateFormat} onChange={(e, a) => handleInputChange({
                target: {
                  name: 'birthdate',
                  value: a
                }
              })} /> */}
              <Select
                placeholder="Día"
                style={{ width: 80, marginRight: 10 }}
                onChange={(e, a) => setFormData({ ...formData, birthdate_day: a.value })}
                options={dayOptions}
              />
              <Select
                placeholder="Mes"
                style={{ width: 80, marginRight: 10 }}
                onChange={(e, a) => setFormData({ ...formData, birthdate_month: a.value })}
                options={monthOptions}
              />
              <Select
                placeholder="Año"
                style={{ width: 80 }}
                onChange={(e, a) => setFormData({ ...formData, birthdate_year: a.value })}
                options={yearOptions}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div htmlFor="myFile" className="input-label">Sube una foto carnet (solo cara, sin lentes ni accesorios)</div>
              <span style={{ color: 'red', marginLeft: 5 }}> *</span>
            </div>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={({ onSuccess }) => setTimeout(() => { onSuccess("ok", null); }, 0)}
              beforeUpload={beforeUpload}
              onChange={handleChangeImage}
            >
              {formData.imageUrl ? (
                <img
                  src={formData.imageUrl}
                  alt="avatar"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div htmlFor="invitedBy" className="input-label">Invitado por:</div>
              <span style={{ color: 'red', marginLeft: 5 }}> *</span>
            </div>
            <input type="text" id="invitedBy" name="invitedBy" placeholder="Juan Jimenez" className="input-text" onChange={handleInputChange} />
            <button type="submit" className="submit-button">{isSubmitting ? 'Enviando...' : 'Enviar'}</button>
          </>
        )}
      </form>

    </div>
  );
};

export default Partners;
