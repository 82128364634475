import React, { useState } from 'react';
import moment from 'moment';
import { message, Spin, QRCode, Upload } from 'antd';
import { getDatabase, ref, set } from "firebase/database";
import { ref as fileRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getStorage } from 'firebase/storage';
import { isImageURL } from './helpers';

const RequestCard = ({ index, form, loading, handleAcceptMember, handleRejectMember }) => {

  const [imageLoading, setImageLoading] = useState(false);

  const storage = getStorage();
  const db = getDatabase();

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Solo puedes subir foto en formato JPG o PNG');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('La foto no puede ser mayor a 2MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleEditMemberPicture = (info) => {
    if (info.file.status === 'uploading') {
      setImageLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      processAndUploadImage(info.file.originFileObj);
    }
  }

  const processAndUploadImage = (event) => {

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = 200;
        canvas.height = 200;

        const scale = Math.min(img.width, img.height) / 200;
        const x = (img.width - 200 * scale) / 2;
        const y = (img.height - 200 * scale) / 2;

        ctx.drawImage(img, x, y, 200 * scale, 200 * scale, 0, 0, 200, 200);

        canvas.toBlob((blob) => {
          // Firebase storage reference
          // Upload the file to Firebase Storage
          const storageRef = fileRef(storage, `images/${form.id}/`);
          uploadBytes(storageRef, blob).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
              editPictureInDatabase(downloadURL);
            }).catch((error) => {
              setImageLoading(false);
            });
          }).catch((error) => {
            setImageLoading(false);
          });
        }, 'image/jpeg');
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(event);
  }

  const editPictureInDatabase = async (url) => {
    form.imageUrl = url;
    set(ref(db, `forms/${form.key}`), form)
      .then(() => {
        message.destroy()
        message.success('Imagen de socio editada')
        setImageLoading(false)
      })
      .catch((error) => {
        console.error('Error submitting data: ', error);
        setImageLoading(false)
      });
  };

  return (
    <div key={index} className="form-card">
      <div style={{ marginBottom: 5 }}>
        <span className="card-title">Nombre: </span>
        <span>{`${form.firstname} ${form.lastname}`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">DNI: </span>
        <span>{`${form.id}`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">Celular: </span>
        <span>{`${form.phone}`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">Mail: </span>
        <span>{`${form.email}`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">Invitado por: </span>
        <span>{`${form.invitedBy}`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">Instagram: </span>
        <span>{`${form.instagram}`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">Playa: </span>
        <span>{`${form.beach}`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">Edad: </span>
        <span>{`${moment().diff(moment(form.birthdate, 'DD/MM/YYYY'), 'years')} años`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">Fecha de registro: </span>
        <span>{`${moment(moment(form.createdAt, 'DD/MM/YYYY')).format('DD/MM/YYYY')}`}</span>
      </div>

      <img
        src={isImageURL(form.imageUrl) ? form.imageUrl : "https://firebasestorage.googleapis.com/v0/b/brizsa-app.appspot.com/o/anonymous.jpeg?alt=media&token=f13d6e1c-5578-4961-b730-96d262870540"}
        alt="avatar"
        style={{
          width: '100%',
        }}
      />
      <Upload
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        customRequest={({ onSuccess }) => setTimeout(() => { onSuccess("ok", null); }, 0)}
        beforeUpload={beforeUpload}
        onChange={handleEditMemberPicture}
      >
        <div style={{ marginTop: 8 }}>
          Nueva imagen
        </div>
      </Upload>
      <div id={`myqrcode${form.id}`}>
        <QRCode value={`https://briszaasia.com/qr/${form.id}`} errorLevel={"L"} color={"#F9F6EE"} style={{ marginBottom: 16, display: 'none' }} />
      </div>
      {/* Display other form data here */}
      {loading || imageLoading ? (<Spin style={{ alignSelf: 'center', marginTop: 20 }} />) : (
        <div className="card-actions">
          <button onClick={() => handleAcceptMember(form.key, form)} className="accept-button">
            Aceptar
          </button>
          <button onClick={() => handleRejectMember(form.key, form)} className="reject-button">
            Eliminar
          </button>
        </div>
      )}
    </div>
  );
};

export default RequestCard;
