import React, { useRef, useState } from 'react';
import moment from 'moment';
import { message, Spin, QRCode, Button, Upload } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import { getDatabase, ref, set } from "firebase/database";
import { ref as fileRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getStorage } from 'firebase/storage';
import { isImageURL } from './helpers';

const PartnersCard = ({ index, form, handlePDF, handleEditMember }) => {

  const [loading, setLoading] = useState(false);

  const storage = getStorage();
  const db = getDatabase();

  const downloadQRCode = () => {
    // Convert QR Code to canvas and then to an image for download
    const canvas = document.getElementById(`myqrcode${form.id}`)?.querySelector('canvas');
    const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const link = document.createElement('a');
    link.download = `qr_${form.id}.png`;
    link.href = image;
    link.click();
  };


  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Solo puedes subir foto en formato JPG o PNG');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('La foto no puede ser mayor a 2MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleEditMemberPicture = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      processAndUploadImage(info.file.originFileObj);
    }
  }

  const processAndUploadImage = (event) => {

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = 200;
        canvas.height = 200;

        const scale = Math.min(img.width, img.height) / 200;
        const x = (img.width - 200 * scale) / 2;
        const y = (img.height - 200 * scale) / 2;

        ctx.drawImage(img, x, y, 200 * scale, 200 * scale, 0, 0, 200, 200);

        canvas.toBlob((blob) => {
          // Firebase storage reference
          // Upload the file to Firebase Storage
          const storageRef = fileRef(storage, `images/${form.id}/`);
          uploadBytes(storageRef, blob).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
              editPictureInDatabase(downloadURL);
            }).catch((error) => {
              setLoading(false);
            });
          }).catch((error) => {
            setLoading(false);
          });
        }, 'image/jpeg');
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(event);
  }

  const editPictureInDatabase = async (url) => {
    form.imageUrl = url;
    set(ref(db, `forms/${form.key}`), form)
      .then(() => {
        set(ref(db, `partners/${form.key}`), form)
          .then(() => {
            message.destroy()
            message.success('Imagen de socio editada')
            setLoading(false)
          })
          .catch((error) => {
            console.error('Error submitting data: ', error);
            setLoading(false)
          });
      })
      .catch((error) => {
        console.error('Error submitting data: ', error);
        setLoading(false)
      });
  };

  const downloadImage = async () => {
    try {
      // Fetch the image
      const response = await fetch(form.imageUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Create a blob from the response data
      const blob = await response.blob();
      // Create a link element
      const link = document.createElement('a');
      // Create a URL for the blob
      link.href = window.URL.createObjectURL(blob);
      link.download = `image_${form.id}.png`;
      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <div key={index} className="form-card">
      <div style={{ marginBottom: 5 }}>
        <span className="card-title">Nombre: </span>
        <span>{`${form.firstname} ${form.lastname}`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">DNI: </span>
        <span>{`${form.id}`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">mail: </span>
        <span>{`${form.email}`}</span>
      </div>
      <div style={{ marginBottom: 10 }}>
        <span className="card-title">Edad: </span>
        <span>{`${moment().diff(moment(form.birthdate, 'DD/MM/YYYY'), 'years')} años`}</span>
      </div>
      {loading ? <Spin /> : (
        <img
          src={isImageURL(form.imageUrl) ? form.imageUrl : "https://firebasestorage.googleapis.com/v0/b/brizsa-app.appspot.com/o/anonymous.jpeg?alt=media&token=f13d6e1c-5578-4961-b730-96d262870540"}
          alt="avatar"
          style={{
            width: '100%',
          }}
        />
      )}

      <div>
        <Button onClick={downloadImage}>Descargar Imagen</Button>
      </div>
      <div>
        <Button onClick={handleEditMember}>Editar miembro</Button>
      </div>
      <div>
        <Upload
          name="avatar"
          listType="picture-card"
          showUploadList={false}
          customRequest={({ onSuccess }) => setTimeout(() => { onSuccess("ok", null); }, 0)}
          beforeUpload={beforeUpload}
          onChange={handleEditMemberPicture}
        >
          <div style={{ marginTop: 8 }}>
            Nueva imagen
          </div>
        </Upload>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div id={`myqrcode${form.id}`} style={{ backgroundColor: 'black', marginTop: 20 }}>
          <QRCode value={`https://briszaasia.com/qr/${form.id}`} errorLevel={"L"} color={"#F9F6EE"} style={{ marginBottom: 16 }} />
          {/* <QR onSVGData={(svgData) => handleQRCodeSVGData(form.key, svgData)} /> */}
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <IdcardOutlined style={{ fontSize: 50 }} />
        </div>
      </div>
      <div style={{ display: 'inline-block', width: '50%' }}>
        <Button onClick={downloadQRCode}>Descargar QR</Button>
      </div>
      <div style={{ display: 'inline-block', width: '50%' }}>
        <Button onClick={handlePDF}>Descargar Carnet</Button>
      </div>
    </div>
  );
};

export default PartnersCard;
