import './App.css';
import './assets/fonts/fonts.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AdminPage } from './Admin'
import { Main } from './Main'
import JustAdmin from './Terms'
import SignupForm from './Auth/Signup';
import LoginForm from './Auth/Login';
import Partners from './Partners';
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import { Verified } from './Verified';
import ProtectedRoute from './ProtectedRoute'; // Assuming you saved it in this path
import { VerifiedIdSearch } from './VerifiedIdSearch';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { database };
export { storage };

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/admin/brisza" element={
          <ProtectedRoute>
            <AdminPage />
          </ProtectedRoute>
        } />
        <Route path="/admin" element={
          <ProtectedRoute>
            <JustAdmin />
          </ProtectedRoute>
        } />
        <Route path="/socios" element={<Partners />} />
        <Route path="/signup_brisza123" element={<SignupForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/dni" element={<VerifiedIdSearch />} />
        <Route path="/qr/:id" element={<Verified />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
