export function dataURLToBlob(dataUrl) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
}

export function isImageURL(url) {
  // Pattern to match a valid Firebase Storage image URL
  const pattern = /\/o\/images%2F[^?]+/;
  return pattern.test(url);
}
