import React, { useState } from 'react';
import { getAuth, signOut } from "firebase/auth";

const Admin = () => {

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log('Logged out');
      // Redirect to login or home page as needed
    }).catch((error) => {
      // An error happened.
      console.error('Logout error', error);
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', color: 'white' }}>
      <div>
        You are and admin
      </div>
      <div onClick={handleLogout} style={{ padding: 15, backgroundColor: "red", borderRadius: 10, cursor: "pointer", fontWeight: 'bold', marginTop: 20 }}>
        Logout
      </div>
    </div>
  );
};

export default Admin;
